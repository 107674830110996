import StartFreeTrialContent from "../components/startFreeTrial/StartFreeTrialContent";
import Features from "../components/startFreeTrial/Features";
import Header from "../components/Header";
import styles from "./css/HOME.module.css";
import Footer from "../components/Footer";
import { useEffect } from "react";
import Settings from "../components/dashboard/Settings";

const StartFreeTrial = () => {
  useEffect(() => {
    document.getElementById("trialDiv").scroll(0, 0);
  }, []);
  return (
    <div className={styles.home} id="trialDiv">
      <img
        className={styles.pageContentIcon}
        alt=""
        src="/page-content@2x.png"
      />
      <Header />
      <main className={styles.rectangleParent} style={{ paddingTop: "100px" }}>
        <div className={styles.frameChild} />
        <StartFreeTrialContent />
        <Features />
        <Footer />
      </main>
      <Settings />
    </div>
  );
};

export default StartFreeTrial;
