import styles from "./Features.module.css";
import { useState, useEffect } from "react";
import CardsContainer from "../dashboard/CardsContainer";
import { Button } from "react-bootstrap";
import Onboarding from "./Onboarding";
import { Link } from "react-router-dom";
import { useContext } from "react";
import StepContext from "../../context/StepContext";
import "../../pages/css/responsive.css";

const Features = () => {
  const [userData, setUserData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const context = useContext(StepContext);
  const { setOnboardingStep } = context;

  return (
    <>
      <div
        className="container justify-content-center"
        style={{ fontFamily: "--font-nunito" }}
      >
        <div
          className="text-white text-center mx-auto"
          style={{ marginTop: "-20px" }}
        >
          <h1
            className="fw-bold resHeading"
            style={{ fontSize: "var(--font-size-39xl)" }}
          >
            Dashboard{" "}
          </h1>
        </div>

        <div
          className="container border"
          style={{
            borderRadius: "var(--br-mini)",
            backgroundColor: "var(--color-gray-200)",
            border: "2px solid var(--color-cadetblue-200) !important",
          }}
        >
          <CardsContainer
            accountBalance={"45,678.90"}
            unrealizedPnL={"-878.20"}
            thirtyDayGain={"5,327.44"}
          />
          <div className="container my-3">
            <Button
              className={`ms-2 ${styles.tag}`}
              name="REPORT"
              variant="primary"
              style={{ backgroundColor: "var(--color-cadetblue)" }}
            >
              REPORT
            </Button>
            <div className="container text-white mt-4 text-center ">
              <div className="table-responsive d-flex justify-content-center flex-column">
                <table>
                  <thead>
                    <tr
                      className=""
                      style={{ color: "var(--color-cadetblue)" }}
                    >
                      <th className="fw-bold text-center" scope="col">
                        Symbol
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Side
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Contracts
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Entry Price
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Unrealized PNL
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Leverage
                      </th>
                      <th className="fw-bold text-center" scope="col">
                        Daily Realized PnL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fw-bold text-center">GALA/USDT:USDT</td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">7.0</td>
                      <td className="fw-bold text-center">0.03148</td>
                      <td className="fw-bold text-center">-0.00287</td>
                      <td className="fw-bold text-center">25</td>
                      <td className="fw-bold text-center">0.0007</td>
                    </tr>

                    <tr>
                      <td className="fw-bold text-center">APE/USDT:USDT</td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">0.1</td>
                      <td className="fw-bold text-center">1.671</td>
                      <td className="fw-bold text-center">-0.00113</td>
                      <td className="fw-bold text-center">25</td>
                      <td className="fw-bold text-center">0.0005</td>
                    </tr>
                    <tr>
                      <td className="fw-bold text-center">ALPHA/USDT:USDT</td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">5.0</td>
                      <td className="fw-bold text-center">0.13217</td>
                      <td className="fw-bold text-center">-0.0086</td>
                      <td className="fw-bold text-center">10</td>
                      <td className="fw-bold text-center">0.0048</td>
                    </tr>
                    <tr>
                      <td className="fw-bold text-center">OP/USDT:USDT</td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">0.2</td>
                      <td className="fw-bold text-center">3.83310743</td>
                      <td className="fw-bold text-center">-0.00827851</td>
                      <td className="fw-bold text-center">25</td>
                      <td className="fw-bold text-center">0.0048</td>
                    </tr>
                    <tr>
                      <td className="fw-bold text-center">INJ/USDT:USDT</td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">4.4</td>
                      <td className="fw-bold text-center">41.39909091</td>
                      <td className="fw-bold text-center">-18.872</td>
                      <td className="fw-bold text-center">25</td>
                      <td className="fw-bold text-center">0.2315</td>
                    </tr>
                    <tr>
                      <td className="fw-bold text-center">
                        1000PEPE/USDT:USDT
                      </td>
                      <td className="fw-bold text-center">Long</td>
                      <td className="fw-bold text-center">100</td>
                      <td className="fw-bold text-center">0.001</td>
                      <td className="fw-bold text-center">-0.03364</td>
                      <td className="fw-bold text-center">10</td>
                      <td className="fw-bold text-center">0.0001</td>
                    </tr>
                  </tbody>
                </table>

                
              </div>
            </div> 
            {/* table div ends */}
            <div className="mt-2 text-center">
                  <Link
                    to="/onboarding"
                    className={styles.tag}
                    onClick={setOnboardingStep(1)}
                    name="onboarding"
                    variant="primary"
                  >
                    <button
                      className="btn btn-lg text-white"
                      style={{ backgroundColor: "var(--color-cadetblue)" }}
                    >
                      Onboarding Toturial
                    </button>
                  </Link>
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
