import React, { useEffect } from 'react'
import { useContext } from 'react'
import AlertContext from '../context/AlertContext'

function Alert() {
    const context = useContext(AlertContext);
    const {alert, setAlert, mode} = context;
    console.log(alert)
    useEffect(() => {
        if (alert) {
            window.scrollTo(0, 0);  // Scroll to the top of the page when alert changes
        }
      }, [alert]);
   
    return (
        <>
        { alert && <div id="mainDiv" className={`rounded-4`} style={{height: "100px", position:"absolute", top: "50%", 
        left: "50%", width:"320px",
        transform: "translate(-50%, -50%)", zIndex:"1056", fontSize: "var(--font-size-lg)"}}>
        <div className={`mx-auto alert alert-${alert.type} fade show`} role="alert" style={{marginBottom: "0px"}}>
         <div className='text-center'> <strong>{alert.msg}</strong> </div>
        </div>
        </div>}</>
    )
}

export default Alert