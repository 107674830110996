import Header from "../components/Header";
import SignupArea from "../components/signup/SignupArea";
import Footer from "../components/Footer";
// import styles from "./css/CreateNewAccount.module.css";
import styles from "../components/startFreeTrial/ONBOARDING.module.css";

const CreateNewAccount = () => {
  return (
    <div className={styles.onboarding}>
      <img className={styles.bodyIcon} alt="" src="/body@2x.png" />
      <Header />
      <main className={styles.rectangleParent} style={{paddingTop: "100px", overflowX: "hidden"}}>
        <div className={styles.frameChild} style={{paddingTop: "100px", overflowX: "hidden"}}/>
        {/* <section className={styles.backgroundShape} /> */}
        
        <SignupArea />
        <Footer />
      </main>
    </div>
  );
};

export default CreateNewAccount;
