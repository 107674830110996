import StepContext from "./StepContext";
import { useState } from "react";

const StepState = (props) => {
    const [onboardingStep, setOnboardingStep] = useState(1); // to be used in onboarding page
    const [configStep, setConfigStep] = useState(1); // to be used in settings modal
    const [signUpStep, setSignUpStep] = useState(1); // to be used in signup page
    const [signUpEmail, setSignUpEmail] = useState(""); // to be used in signup page
    const [listOfCoins, setListOfCoins] = useState([]); // to be used in settingsCoin component
  return (
    <StepContext.Provider
      value={{onboardingStep, setOnboardingStep, configStep, setConfigStep, signUpStep, setSignUpStep, signUpEmail, setSignUpEmail, listOfCoins, setListOfCoins}}
    >
      {props.children}
    </StepContext.Provider>
  );
  };

export default StepState;
