import React from "react";
import { useEffect } from "react";
import { InlineWidget } from "react-calendly";



const Calendly = (props) => {

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);

    function isCalendlyEvent(e) {
      return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    };
     
    window.addEventListener("message", function(e) {
      if(isCalendlyEvent(e)) {

        // /* Example to get the name of the event */
        console.log("Event name:", e.data.event);
        if (e.data.event === "calendly.event_scheduled")
          {console.log("event has been scheduled")
        setTimeout(() => {
          props.setRequestDemoStep(2)
      }, 4000);
          }
        
        // /* Example to get the payload of the event */
        console.log("Event details:", e.data.payload);
      }
    });

  }, []);


  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/stablesail/everything-you-need-to-know?background_color=797978&text_color=ffffff&primary_color=3d86ee"
      style={{ height: "700px", border: "2px, solid var(--color-cadetblue)"}}
    >
    {/* background colors similar 797978 D4D4CE */}
     {/* <!-- Calendly inline widget begin --> */}

     {/* <div className="calendly">
      <InlineWidget url="https://calendly.com/stablesail/everything-you-need-to-know?background_color=1e00ff&text_color=ffffff&primary_color=e2edfd" 
      
      /> D4D4CE
    </div> */}
 
      </div>
    
  );
};

export default Calendly;


