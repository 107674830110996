import CardsContainer from "./CardsContainer";
import styles from "../../pages/css/Dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import AlertContext from "../../context/AlertContext";
import StepContext from "../../context/StepContext";

const GroupComponentDashboard = ({ groupHeaderPadding, groupHeaderWidth }) => {
let navigate = useNavigate();

  const context = useContext(AlertContext);
  const { showAlert } = context;
  const {setListOfCoins, listOfCoins} = useContext(StepContext)

  const [userData, setUserData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    // Fetch user information and dashboard data from the backend API
    const fetchDashboardData = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      if (!accessToken) {
        // If access token is not available, wait and retry after 1 second
        setTimeout(fetchDashboardData, 1000);
        return;
      }
      try {
        // Make a POST request to fetch user information
        console.log(accessToken);
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/verify_t/${accessToken}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Make a POST request to fetch dashboard data
        const dashboardResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/`,
          {
            email: userResponse.data.email,
            username: userResponse.data.username,
          }
        );

        const listCoins = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/list_coins/`,
          {
            email: userResponse.data.email,
            username: userResponse.data.username,
          }
        )
        console.log('list_coins', listCoins.data)
        setListOfCoins(listCoins.data)
        // Set user information and dashboard data in state
        setUserData(userResponse.data);
        //email = userData.email
        console.log(userData);

        setDashboardData(dashboardResponse.data);
        console.log(`sss ${dashboardResponse.data}`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showAlert("Error fetching dashboard data", "danger", () => {
          navigate("/login"); sessionStorage.removeItem("accessToken");
        });
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    console.log('list_coins context', listOfCoins);
  }, [listOfCoins])


  const groupHeaderStyle = useMemo(() => {
    return {
      padding: groupHeaderPadding,
      width: groupHeaderWidth,
    };
  }, [groupHeaderPadding, groupHeaderWidth]);


  const formatNumber = (value) => {
    return typeof value === 'number' ? value.toFixed(2) : '0.00';
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div
          className="container justify-content-center"
          style={{ fontFamily: "--font-nunito" }}
        >
          {/* <div className="text-white text-center mx-auto" style={{marginTop: "0px"}}> <h1 className="fw-bold">Dashboard {userData?.[1] ?? ""}</h1></div> */}

          <div
            className="container border"
            style={{
              borderRadius: "var(--br-mini)",
              backgroundColor: "var(--color-gray-200)",
              border: "2px solid var(--color-cadetblue-200) !important",
            }}
          >
            <CardsContainer
              accountBalance={formatNumber(dashboardData?.[0] ?? "")}
              unrealizedPnL={formatNumber(dashboardData?.[1] ?? "")}
              thirtyDayGain={formatNumber(dashboardData?.[2] ?? "")}
              tableData={formatNumber(dashboardData?.[3] ?? [])}
            />
            <div className="container mb-4">
              <Button
                className={styles.tag}
                name="REPORT"
                variant="primary"
                style={{ backgroundColor: "var(--color-cadetblue)" }}
              >
                REPORT
              </Button>
              <div className="container text-white mt-4 text-center ">
                <div className="table-responsive d-flex justify-content-center">
                  <table>
                    <thead>
                      <tr style={{ color: "var(--color-cadetblue)" }}>
                        <th className="fw-bolder text-center">Symbol</th>
                        <th className="fw-bolder text-center">Side</th>
                        <th className="fw-bolder text-center">
                          Number of Contracts
                        </th>
                        <th className="fw-bolder text-center">Entry Price</th>
                        <th className="fw-bolder text-center">
                          Unrealized PNL
                        </th>
                        <th className="fw-bolder text-center">Leverage</th>
                        <th className="fw-bolder text-center">
                          Daily Realized PnL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData && dashboardData[3] ? (
                        dashboardData[3].map((item, index) => (
                          <tr key={index}>
                            <td className="fw-bolder text-center">
                              {item.symbol}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.side}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.num_of_contracts.toFixed(2)}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.entry_price.toFixed(2)}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.upnl.toFixed(2)}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.leverage.toFixed(2)}
                            </td>
                            <td className="fw-bolder text-center">
                              {item.daily_realized_pnl.toFixed(2)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="7"
                            style={{ textAlign: "center", fontSize: "1.5em" }}
                          >
                            Loading...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupComponentDashboard;
