import { useState } from "react";
import styles from "../signup/GroupComponentCreateNewAccount.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';
import { useContext } from 'react'
import AlertContext from '../../context/AlertContext'

const GroupComponentCreateNewAccount = () => {

  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const context = useContext(AlertContext);
    const {showAlert} = context;

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {

      try {
        const resp = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`
            }
          } 
        )

        const credentials = {
          email: resp.data.email,
          password: '1',
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login/`,
          credentials
        );
        if (response.status === 200) {
          // Assuming the access token is returned in the response
          const accessToken = response.data.access_token;
          // Store access token in sessionStorage
          sessionStorage.setItem("accessToken", accessToken);
          console.log("Login successful");
          showAlert("Login Succesful. Loading data from Database. Please wait ...", "primary", ()=>{
            navigate("/dashboard1");
          });
          // alert("Login Succesful");
        } else {
          console.log("Login failed");
          showAlert("Login Failed", "primary");
        }
      } catch (err) {
        if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == false){
          showAlert("API Key Invalid, Please signup with Google again", "danger", () => {
            navigate("/signup");
          });
        }
        // console.log("Token or Login Error", error);
        // // alert("Incorrect Credentials");
        // showAlert("Enter Valid Credentials", "danger");
      }
    },
  });

  let navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "", otp: "" });
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    console.log(credentials);
  };
  const submit = async () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    console.log(process.env.REACT_APP_BACKEND_URL);
    if (step === 1) {
      if (!credentials.email || !credentials.password) { showAlert("Email and Password are Required!", "danger")}
      else if (!regex.test(credentials.email)) {
        showAlert("Please Enter a Valid Email Address!", "danger")
      }
      else {
        handleNextStep();
      }
    }
    if (step ===2) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login/`,
          credentials
        );
        if (response.status === 200) {
          // Assuming the access token is returned in the response
          const accessToken = response.data.access_token;
          // Store access token in sessionStorage
          sessionStorage.setItem("accessToken", accessToken);
          console.log("Login successful");
          showAlert("Login Succesful. Loading data from database. Please wait ....", "primary", ()=>{
            navigate("/dashboard1");
          });
          
        } else {
          console.log("Login failed");
          showAlert("Login Failed", "danger");
        }
      } catch (err) {
        console.log(err.response.data.detail)
        if (err.response.data.detail.is_email_exists == true && err.response.data.detail.is_api_valid == false){
          showAlert("API Key Invalid, Please signup again", "danger", () => {
            navigate("/signup");
          });
        } else {
              showAlert(err.response.data.detail, "danger");
              handlePreviousStep();
        }
        
      }
    }
    
  };

  return (
    <>
    {step === 1 && (
      <div className={styles.rectangleParent} style={{}}>
      <div className={styles.frameChild} />
      <div className={styles.signupTitle}>
        <h1 className={styles.createAnAccount}>Login</h1>
        <div className={styles.enterYourEmail}>
          Enter your email to sign in to StableSail
        </div>
      </div>

      
      <div className={styles.emailInput}>
        <div className={styles.email}>Email</div>
        <div className={styles.inputField}>
          <input
            type="email"
            className={styles.inputField}
            style={{
              display: "inline-block",
              minWidth: "100%",
              background: "rgba(0, 0, 0, 0.2)",
              paddingLeft: "10px",
            }}
            placeholder="name@example.com"
            id="email"
            name="email"
            onChange={onChange}
          />
          <div className={styles.emailAddress}></div>
        </div>
      </div>

      <div className={styles.emailInput}>
        <div className={styles.email}>Password</div>
        <div className={styles.inputField}>
          <input
            type="password"
            className={styles.inputField}
            style={{
              display: "inline-block",
              minWidth: "100%",
              background: "rgba(0, 0, 0, 0.2)",
              paddingLeft: "10px",
            }}
            id="password"
            name="password"
            onChange={onChange}
          />
          <div className={styles.emailAddress}></div>
        </div>
      </div>

      <Link
        role="button"
        // to="/dashboard1"
        className={styles.inputField1}
        onClick={submit}
        style={{ border: "var(--color-cadetblue-200)" }}
      >
        <div className={styles.signUpWith}>Sign In</div>
      </Link>
      <div className={styles.socialLogin}>
        <div className={styles.socialIcons}>
          <div className={styles.iconShapes} />
        </div>
        <div className={styles.orContinueWith}>or continue with</div>
        <div className={styles.socialIcons1}>
          <div className={styles.socialIconsChild} />
        </div>
      </div>
      <div className={styles.googleLogin}>
        <Link
          onClick={() => login()}
          className={`text-decoration-none ${styles.loginregisterWith}`}
          style={{ color: "var(--color-cadetblue-100)" }}
        >
          <img className={styles.googleIcon} alt="" src="/google.svg" />
          <div className={styles.google}>Google</div>
        </Link>
        <div className={styles.byClickingContinueContainer}>
          <span>{`By clicking continue, you agree to our `}</span>
          <span className={styles.termsOfService}>Terms of Service</span>
          <span>{` and `}</span>
          <span className={styles.privacyPolicy}>Privacy Policy</span>
        </div>
      </div>
    </div>
    )}
    {step === 2 && (
      <div className={styles.rectangleParent} style={{}}>
      <div className={styles.frameChild} />
      <div className={styles.signupTitle}>
        <h1 className={styles.createAnAccount}>2FA</h1>
        <div className={styles.enterYourEmail}>
          Enter Google Authenticator Code to sign in to StableSail
          <div className={styles.emailInput}>
        <div className={styles.email}>OTP</div>
        
          <input
            type="password"
            className={styles.inputField}
            style={{
              display: "",
              minWidth: "100%",
              background: "rgba(0, 0, 0, 0.2)",
              paddingLeft: "10px", position: "relative", zIndex: "1100"
            }}
            id="otp"
            name="otp"
            onChange={onChange}
          />
          <div className={styles.emailAddress}></div>
        
      </div>
        </div>
        
      </div>
      <Link
        role="button"
        // to="/dashboard1"
        className={styles.inputField1}
        onClick={submit}
        style={{ border: "var(--color-cadetblue-200)" }}
      >
        <div className={styles.signUpWith}>Sign In</div>
      </Link>
      </div>
      
    )}

    </>
  );
};

export default GroupComponentCreateNewAccount;
