import React from 'react'

function SettingsCoin({coin, toggleCoinSelection}) {

    
  return (
      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={coin}
                          // checked={selectedCoins.includes("SHIBA")}
                          // onChange={toggleCoinSelection()}
                          onChange={(e) => toggleCoinSelection(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          {coin}
                        </label>
                      </div>
    
  )
}

export default SettingsCoin
