import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./GroupComponentCreateNewAccount.module.css";
import axios from "axios";
import AlertContext from '../../context/AlertContext'

const CountdownTimer = (props) => {
  const [count, setCount] = useState(60);
  const context = useContext(AlertContext);
  const {showAlert} = context;

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
        // console.log(count)
      }, 1000);
      return () => clearInterval(timer); // Cleanup the interval on component unmount
    }
  }, [count]);

  const resendCode = async () => {
    console.log("resendcode is clicked");
    console.log(props.credentials.email)
    console.log(props.credentials.email)
    const resend_code_credentials = {
      email: props.credentials.email,
      username: props.credentials.email
    }
    const resend = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/resend_code/`,
      resend_code_credentials
    )
    if (resend.status == 200) {

      console.log('success')
      showAlert('Verificaion Email Resent Successfully', 'primary')
    }
  };

  return (
      <Link 
        id="linkResendCode"
        className="text-white text-decoration-none"
        disabled
        onClick={resendCode}
        style={{ pointerEvents: count > 0 ? "none" : "auto", fontFamily: "var(--font-nunito)", fontWeight:"700"}}
      >
        <div className={`${styles.orContinueWith}`}>
          Resend Code {count > 0 ? <span>in {count} sec</span> : null}
        </div>
      </Link>
  );
};

export default CountdownTimer;
