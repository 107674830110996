import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function BlogCorousel() {
  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="4000">
      <img src="/card-image@2x.png" className="d-block w-100" alt="..." />
      
    </div>
    <div className="carousel-item active" data-bs-interval="4000">
      <img src="/card-image@2x.png" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item active" data-bs-interval="4000">
      <img src="/card-image@2x.png" className="d-block w-100" alt="..." />
    </div>
  </div>

</div>

    </div>
  )
}



export default BlogCorousel

