import AlertContext from "./AlertContext";
import { useState } from "react";

const AlertState = (props) => {
    const [mode, setMode] = useState("primary");
    const [alert, setAlert] = useState("");

    const showAlert = (message, type, callback)=>{
      setAlert({
        msg: message,
        type: type
      })
      setTimeout(() => {
          setAlert(null);
          if (typeof callback === 'function') {
            callback(); // Call the callback function if it's defined and is a function
          }
      }, 3000);
      
  }

  return (
    <AlertContext.Provider
      value={{mode, setMode, alert, setAlert, showAlert}}
    >
      {props.children}
    </AlertContext.Provider>
  );
  };

export default AlertState;
