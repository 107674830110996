import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Calendly from "../components/Calendly";
import AlertContext from "../context/AlertContext"
const RequestDemo = () => {

  const context = useContext(AlertContext)
  const {showAlert} = context
  const [requestDemoStep, setRequestDemoStep] = useState(1);
  const [displayCalendly, setDisplayCalendly] = useState(false);

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e)=>{
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    console.log(credentials);
  }

  useEffect(() => {
    if (requestDemoStep === 2) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [requestDemoStep]);

  useEffect(() => {
    if (displayCalendly) {
      const calendlyDiv = document.getElementById('calendlyDiv');
      if (calendlyDiv) {
        calendlyDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [displayCalendly]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.name) {
      // alert("Please enter your name.");
      showAlert("Please enter your name.", "danger")
      return; // Prevent form submission if name is empty
    }
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/leads`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      // setRequestDemoStep(2); // Move to the next step if the submission is successful
      setDisplayCalendly(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{backgroundImage:"url(/page-content@2x.png)", backgroundSize: "cover"}}>
      
      <Header />
      <div style={{backgroundColor: "var(--color-gray-200)", paddingTop: "40px"}}>
        
       {requestDemoStep ===1 &&  (<section className="text-white container text-center mt-4" style={{fontFamily: "var(--font-nunito)"}}>
          <div className="">
            <div className="">
              <h1 className="resHeading"  style={{fontSize: "var(--font-size-39xl)", fontWeight: "600"}}>
                Discover How StableSail Can Transform Your Trading
              </h1>
            </div>
            <div className="mt-2">
              <h4 className="resText" style={{fontSize: "var(--font-size-13xl)"}}>
                We'll guide you through setting up StableSail. We’ll create an
                API allowing the strategies to optimize your bot and amplify the
                trading potential. You will see StableSail in action. Request your Demo now.
              </h4>
            </div>
          </div>
        </section>)}

        {requestDemoStep === 1 && (<section
          className="d-flex justify-content-center container mt-5 pt-2"
          style={{ width: "100%" }}
        >
          <div
            className="container col-md-5 rounded-4"
            style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            <div
              className="container text-white my-3"
              style={{ fontFamily: "var(--font-nunito)" }}
            >
              <div className="ms-2">
                <h4 className="fw-bold"> Lead Form Integration</h4>
              </div>
              <form className="d-block container">
                <div className="mb-3 text-white">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name
                  </label>
                  <input
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "var(--color-cadetblue-100)", color: "white"
                    }}
                    type="text"
                    className="form-control"
                    id="leadName"
                    name="name"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 text-white">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email (Optional)
                  </label>
                  <input
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "var(--color-cadetblue-100)", color: "white"
                    }}
                    type="email"
                    className="form-control"
                    id="leadEmail"
                    name="email"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 text-white">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Phone (Optional)
                  </label>
                  <input
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "var(--color-cadetblue-100)", color: "white"
                    }}
                    type="text"
                    className="form-control"
                    id="leadEmail"
                    name="phone"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-grid gap-2 col-8 mx-auto rounded-3 text-white"
                  style={{ backgroundColor: "var(--color-cadetblue-200)" }}
                >
                  <button
                    class="btn text-white"
                    style={{ fontWeight: "600" }}
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>)}




        {(requestDemoStep === 1 && displayCalendly) && (<section id="calendlyDiv"
          className="d-flex justify-content-center container flex-column"
          style={{ width: "100%", overflow: "hidden", marginTop:"80px"}}
        >
          <div className="container text-center text-white fw-bold" style={{fontSize: "var(--font-size-39xl)", fontFamily: "var(--font-nunito)"}}>Calendly Scheduling</div>

          <div
            className="container"
            style={{ fontFamily: "var(--font-nunito)", overflow: "hidden", marginTop: "-30px"}}
          >
              <Calendly setRequestDemoStep={setRequestDemoStep} style={{height:"1000px"}}/>
            
          </div>
        </section>)}

       {requestDemoStep === 2 &&  (<section
          className="d-flex justify-content-center container"
          style={{ width: "100%", paddingTop: "40px" }}
        >
          <div
            className="col-md-10 col rounded-4  d-flex flex-column"
            style={{ backgroundColor: "var(--color-gray-200)" }}
          >
            <div
              className="text-white border-bottom p-4"
              style={{ fontFamily: "var(--font-nunito)" }}
            >
              <div className="ms-2 text-center">
                <h4> Your demo is booked! 🚀 Get ready to explore how StableSail can
            transform your trading strategy. Check your inbox for the calendar
            invite.</h4>
              </div>
            </div>

            <div
              className="container text-white py-4"
              style={{ fontFamily: "var(--font-nunito)" }}
            >
              <div className="ms-2">
              <h5>Hi {credentials.name},</h5>
            <br />
                <h5> Great news - your StableSail demo is scheduled! We're excited to
              show you how our platform can elevate your trading game. You'll
              learn about API integration, the key benefits of StableSail, and
              how to set up your bot for success.</h5>
              <h5>
               📅 **Here's your calendar invite** add to your calendar to lock
              in our meeting. This conversation will be a game-changer for your
              trading.</h5>
            
            <br />
            <h5>See you soon,</h5>
            <h5>The StableSail Team</h5>
              </div>
            </div>


          </div>
        </section>)}

        <Footer />
      </div>
    </div>
  );
};

export default RequestDemo;
