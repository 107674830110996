import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import parse from "html-react-parser";
import "./BlogDetail.css"; // Import your CSS file

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/blogs/${id}`
        );
        setBlog(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setError("Failed to load blog details.");
      }
    };

    fetchBlogDetail();
  }, [id]);

  if (error) return <div>{error}</div>;
  if (!blog) return <div>Loading...</div>;

  // Construct the full image URL
  const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/static/${blog.path}`;

  // Function to add CSS classes to images and headers in the HTML content
  const addImageClassToHtml = (htmlString) => {
    let updatedHtml = htmlString.replace(
      /<img /g,
      '<img class="resized-image" '
    );
    updatedHtml = updatedHtml.replace(/<h1/g, '<h1 class="custom-header"');
    updatedHtml = updatedHtml.replace(/<h2/g, '<h2 class="custom-subheader"');
    updatedHtml = updatedHtml.replace(
      /<h3/g,
      '<h3 class="custom-subsubheader"'
    );
    updatedHtml = updatedHtml.replace(/<p/g, '<p class="blog-content"');
    return updatedHtml;
  };

  // Parse and update HTML content
  const contentWithClass = addImageClassToHtml(blog.content);

  return (
    <div
      style={{
        backgroundImage: "url(/page-content@2x.png)",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <div style={{ backgroundColor: "var(--color-gray-200)" }}>
        <section
          className="container text-white"
          style={{
            width: "100%",
            paddingTop: "40px",
            fontFamily: "var(--font-nunito)",
          }}
        >
          <div className="container">
            {/* Render the blog title as an h1 element */}
            <h1 className="custom-header text-center fw-bolder">
              {blog.title}
            </h1>
            <div className="mt-1 text-center">
              <div className="mx-auto" style={{ width: "300px" }}>
                <img src={imageUrl} alt={blog.title} className="img-fluid" />
              </div>
              <div className="blog-content">{parse(contentWithClass)}</div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetail;
