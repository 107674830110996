import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import CreateNewAccount from "./pages/CreateNewAccount";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Dashboard1 from "./pages/Dashboard1";
import StartFreeTrial from "./pages/StartFreeTrial";
import Onboarding from "./components/startFreeTrial/Onboarding";
import RequestDemo from "./pages/RequestDemo";
import HowItWorks from "./pages/HowItWorks";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import BlogEntry from "./pages/BlogEntry";
import BlogDetail from "./components/blogs/BlogDetail"
import Alert from "./components/Alert"
import CountdownTimer from "./components/signup/CountdownTimer";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (

<div>
  <Alert />
   <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<CreateNewAccount />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard1" element={<Dashboard1 />} />
      <Route path="/startfreetrial" element={<StartFreeTrial />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/requestdemo" element={<RequestDemo />} />
      <Route path="/howitworks" element={<HowItWorks />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blogentry" element={<BlogEntry />} />
      <Route path="/countdown" element={<CountdownTimer />} />
    </Routes>
    
   </div>
  );
}
export default App;
