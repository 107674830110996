import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Slider as MuiSlider, Box } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "@mui/material/Slider";
import axios from "axios";
import "./Settings.css";
import StepContext from "../../context/StepContext";
import SettingsCoin from "./SettingsCoin";
import AlertContext from "../../context/AlertContext";

const CustomSlider = styled(Slider)({
  color: "primary",
});

function Settings() {
  const context = useContext(StepContext);
  const { configStep, setConfigStep, listOfCoins, setListOfCoins } = context;
  const { showAlert } = useContext(AlertContext);

  const [walletRisk, setWalletRisk] = useState(50);
  const [randomForest, setRandomForest] = useState(50);
  const [xgboost, setXgboost] = useState(50);
  const [longMinMarkup, setLongMinMarkup] = useState(50);
  const [longMarkupWeight, setLongMarkupWeight] = useState(200);
  const [shortMinMarkup, setShortMinMarkup] = useState(50);
  const [minMarkupWeight, setMinMarkupWeight] = useState(50);
  const [emaWeight, setEmaWeight] = useState(50);
  const [WVMAWeight, setWVMAWeight] = useState(50);
  const [leverage, setLeverage] = useState(200);
  const [timeOffset, setTimeOffset] = useState(50);
  const [priceThreshold, setPriceThreshold] = useState(50);
  const [userData, setUserData] = useState(null);
  const [botOperation, setBotOperation] = useState("1"); // Default value for bot operation
  const [selectedCoins, setSelectedCoins] = useState([]);

  const handleWalletRisk = (event, newValue) => {
    setWalletRisk(newValue);
    console.log(`wallet risk ${newValue}`);
  };
  const handleRandomForest = (event, newValue) => {
    setRandomForest(newValue);
    console.log(`random forest ${newValue}`);
  };
  const handleXgboost = (event, newValue) => {
    setXgboost(newValue);
    console.log(`XGBOOST ${newValue}`);
  };
  const handleLongMinMarkup = (event, newValue) => {
    setLongMinMarkup(newValue);
    console.log(`Long Min Markup ${newValue}`);
  };
  const handleLongMarkupWeight = (event, newValue) => {
    setLongMarkupWeight(newValue);
    console.log(`Long Markup Weight ${newValue}`);
  };
  const handleShortMinMarkup = (event, newValue) => {
    setShortMinMarkup(newValue);
    console.log(`Short Min Markup ${newValue}`);
  };
  const handleMinMarkupWeight = (event, newValue) => {
    setMinMarkupWeight(newValue);
    console.log(`Min Markup Weight ${newValue}`);
  };
  const handleEmaWeight = (event, newValue) => {
    setEmaWeight(newValue);
    console.log(`EMA WEIGHT ${newValue}`);
  };
  const handleWVMAWeight = (event, newValue) => {
    setWVMAWeight(newValue);
    console.log(`WVMA Weight ${newValue}`);
  };
  const handleLeverage = (event, newValue) => {
    setLeverage(newValue);
    console.log(`Leverage ${newValue}`);
  };
  const handleTimeOffset = (event, newValue) => {
    setTimeOffset(newValue);
    console.log(`Time Offset ${newValue}`);
  };
  const handlePriceThreshold = (event, newValue) => {
    setPriceThreshold(newValue);
    console.log(`Price Threshold ${newValue}`);
  };
  const percentageWalletRisk = (walletRisk / 100) * 100;
  const percentageRandomForest = (randomForest / 100) * 100;
  const percentageXgboost = (xgboost / 100) * 100;
  const percentageLongMinMarkup = (longMinMarkup / 100) * 100;
  const percentageLongMarkupWeight = (longMarkupWeight / 100) * 100;
  const percentageShortMinMarkup = (shortMinMarkup / 100) * 100;
  const percentageMinMarkupWeight = (minMarkupWeight / 100) * 100;
  const percentageEmaWeight = (emaWeight / 100) * 100;
  const percentageWVMAWeight = (WVMAWeight / 100) * 100;
  const percentageLeverage = (leverage / 100) * 100;
  const percentageTimeOffset = (timeOffset / 100) * 100;
  const percentagePriceThreshold = (priceThreshold / 100) * 100;

  const coinStep = () => {
    setConfigStep(3);
  };

  const mainStep = () => {
    setConfigStep(1);
  };

  const settingsStep = () => {
    setConfigStep(2);
  };

  const toggleCoinSelection = (coin) => {
    if (selectedCoins.includes(coin)) {
      setSelectedCoins(selectedCoins.filter((c) => c !== coin));
    } else {
      setSelectedCoins([...selectedCoins, coin]);
    }
  };

  const handleAllSliders = async () => {
    console.log("Save Changes is clicked");
    let success = true;
    if (success) {
      showAlert(
        "Your changes have been saved! We're working behind the scenes to update them (may take a couple of minutes)",
        "primary"
      );
    }
    if (configStep === 1) {
      const accessToken = sessionStorage.getItem("accessToken");
      if (!accessToken) {
        // If access token is not available, wait and retry after 1 second
        setTimeout(fetchDashboardData, 1000);
        return;
      }
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/verify_t/${accessToken}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (botOperation === "1") {
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_one/`, {
            user: {
              email: userResponse.data.email,
              username: userResponse.data.username,
            },
            params: {
              parameter: true,
            },
          });
        } else if (botOperation === "2") {
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_one/`, {
            user: {
              email: userResponse.data.email,
              username: userResponse.data.username,
            },
            params: {
              parameter: false,
            },
          });
        }
      } catch (error) {
        console.error("Error username for settings:", error);
        success = false;
      }
    }
    if (configStep === 2) {
      const accessToken = sessionStorage.getItem("accessToken");
      if (!accessToken) {
        // If access token is not available, wait and retry after 1 second
        setTimeout(fetchDashboardData, 1000);
        return;
      }
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/verify_t/${accessToken}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log(`username: ${userResponse.data.username}`);

        // axios posts to twelve different settings
        // wallet risk post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "wallet risk",
            value: walletRisk / 100,
          },
        });

        // ema weight post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "ema weight",
            value: emaWeight / 100,
          },
        });

        // wvma weight post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "wvma weight",
            value: WVMAWeight / 100,
          },
        });

        // leverage post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "leverage",
            value: leverage / 100,
          },
        });

        // time offset post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "time offset",
            value: timeOffset / 100,
          },
        });

        // price threshold post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "price threshold",
            value: priceThreshold / 100,
          },
        });

        // random forest post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "random forest",
            value: randomForest / 100,
          },
        });

        // xgboost post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "xgboost",
            value: xgboost / 100,
          },
        });

        // long min markup post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "long min markup",
            value: longMinMarkup / 100,
          },
        });

        // long markup weight post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "long markup weight",
            value: longMarkupWeight / 100,
          },
        });

        // short min markup post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "short min markup",
            value: shortMinMarkup / 100,
          },
        });

        // short markup weight post
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings_two/`, {
          user: {
            email: userResponse.data.email,
            username: userResponse.data.username,
          },
          params: {
            parameter: "short markup weight",
            value: minMarkupWeight / 100,
          },
        });
      } catch (error) {
        console.error("Error username for settings:", error);
        success = false;
      }
    }
    if (configStep === 3) {
      console.log("Selected coins:", selectedCoins);
      const accessToken = sessionStorage.getItem("accessToken");
      if (!accessToken) {
        // If access token is not available, wait and retry after 1 second
        setTimeout(fetchDashboardData, 1000);
        return;
      }
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/verify_t/${accessToken}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const settings_three = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/settings_three/`,
          {
            user: {
              email: userResponse.data.email,
              username: userResponse.data.username,
            },
            params: {
              parameter: selectedCoins,
            },
          }
        );
        console.log("settings three updated", settings_three);
      } catch (error) {
        console.error("Error username for settings:", error);
        success = false;
      }
    }
    if (!success) {
      showAlert("Some error occured while saving...", "danger");
    }
  };

  return (
    <div>
      {/* Modal  */}
      <div
        className="modal fade my-auto"
        id="dashboardModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // style={{position: "relative"}}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "850px" }}
        >
          <div
            className="modal-content text-white my-auto"
            style={{
              backgroundColor: "var(--color-gray-200)",
              Height: "650px",
            }}
          >
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">
                Settings
              </h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex flex-md-row flex-column align-items-center align-items-md-start">
              <div
                id="iconDiv"
                className="rounded-2"
                style={{ width: "300px" }}
              >
                <div
                  className="my-4 fs-5 text-center"
                  style={{ width: "150px" }}
                >
                  Bot Manager
                </div>
                <div
                  className="d-flex flex-row justify-content-center"
                  onClick={mainStep}
                  style={{
                    cursor: "pointer",
                    color:
                      configStep === 1 ? "var(--color-cadetblue)" : "white",
                  }}
                >
                  <div>
                    <img
                      loading="lazy"
                      alt=""
                      src="/home.png"
                      style={{
                        filter:
                          configStep === 1
                            ? "brightness(0) invert(50%) sepia(10%) saturate(3257%) hue-rotate(141deg) brightness(103%) contrast(85%) "
                            : "none",
                      }}
                    />
                  </div>
                  <div className="mx-2 my-auto">
                    <a>Main</a>
                  </div>
                </div>

                <div
                  className="d-flex flex-row justify-content-center my-4"
                  onClick={settingsStep}
                  style={{
                    cursor: "pointer",
                    color:
                      configStep === 2 ? "var(--color-cadetblue)" : "white",
                  }}
                >
                  <div>
                    <img
                      loading="lazy"
                      alt=""
                      src="/search.svg"
                      style={{
                        filter:
                          configStep === 2
                            ? "brightness(0) invert(50%) sepia(10%) saturate(3257%) hue-rotate(141deg) brightness(103%) contrast(85%) "
                            : "none",
                      }}
                    />
                  </div>
                  <div className="mx-2 my-auto">
                    <a>Settings</a>
                  </div>
                </div>

                <div
                  id="coin"
                  onClick={coinStep}
                  style={{
                    cursor: "pointer",
                    color:
                      configStep === 3 ? "var(--color-cadetblue)" : "white",
                  }}
                  className="d-flex flex-row justify-content-center"
                >
                  <div>
                    <img
                      loading="lazy"
                      alt=""
                      src="/bell.svg"
                      style={{
                        filter:
                          configStep === 3
                            ? "brightness(0) invert(50%) sepia(10%) saturate(3257%) hue-rotate(141deg) brightness(103%) contrast(85%) "
                            : "none",
                      }}
                    />
                  </div>
                  <div className="mx-2 my-auto">
                    <div>Coins</div>
                  </div>
                </div>
              </div>
              {configStep === 1 && (
                <div
                  className="container border-start"
                  style={{ borderColor: "blue" }}
                >
                  <div className="mt-1 pt-4 ps-md-3">
                    {" "}
                    <div className="mb-3">
                      <h4>Main Settings</h4>
                    </div>
                    <div className="py-3">Bot Operation</div>
                    <select
                      className="form-select text-white"
                      aria-label="Default select example"
                      style={{
                        width: "200px",
                        backgroundColor: "var(--color-gray-200)",
                      }}
                      value={botOperation} // Set value of select element based on state
                      onChange={(e) => setBotOperation(e.target.value)} // Event listener for change in value
                    >
                      <option value="1">On</option>
                      <option value="2">Off</option>
                      {/* <option
                        selected
                        value="1"
                        className=""
                        style={{
                          backgroundColor: "var(--color-gray-200)",
                        }}
                      >
                        On
                      </option>

                      <option
                        value="2"
                        style={{
                          backgroundColor: "var(--color-gray-200)",
                        }}
                      >
                        Off
                      </option> */}
                    </select>
                    <div className="py-3 pb-2 mb-2">Pause New Orders</div>
                    <select
                      className="form-select text-white"
                      aria-label="Default select example"
                      style={{
                        width: "200px",
                        backgroundColor: "var(--color-gray-200)",
                      }}
                    >
                      <option
                        selected
                        value="1"
                        className=""
                        style={{
                          backgroundColor: "var(--color-gray-200)",
                        }}
                      >
                        On
                      </option>

                      <option
                        value="2"
                        style={{
                          backgroundColor: "var(--color-gray-200)",
                        }}
                      >
                        Off
                      </option>
                    </select>
                    <div className="mb-5 pb-5 mt-4">
                      {/* <button
                          type="button"
                          style={{
                            width: "200px",
                            backgroundColor: "transparent",
                          }}
                          className="btn btn-secondary"
                        >
                          Save Changes
                        </button> */}
                    </div>
                  </div>
                </div>
              )}
              {/* settings window starts */}

              {configStep === 2 && (
                <div
                  className="container border-start"
                  style={{ borderColor: "blue" }}
                >
                  <div className="mt-1 pt-4 ps-md-3">
                    {" "}
                    <div className="mb-3">
                      <h4>Bot Function</h4>
                    </div>
                    <div className="py-3">Strategy Selection</div>
                    <div className="table-responsive">
                      <table className="borderless">
                        <tbody className="borderless">
                          <tr className="borderless">
                            <td className="borderless">Wallet Risk</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={walletRisk}
                                  onChange={handleWalletRisk}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageWalletRisk.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless">Random Forest</td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={randomForest}
                                  onChange={handleRandomForest}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageRandomForest.toFixed(
                              0
                            )}%`}</td>
                          </tr>

                          <tr className="borderless">
                            <td className="borderless">EMA Weight</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={emaWeight}
                                  onChange={handleEmaWeight}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageEmaWeight.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless" style={{}}>
                              XGBOOST
                            </td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={xgboost}
                                  onChange={handleXgboost}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageXgboost.toFixed(
                              0
                            )}%`}</td>
                          </tr>

                          <tr className="borderless">
                            <td className="borderless">WVMA Weight</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={WVMAWeight}
                                  onChange={handleWVMAWeight}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageWVMAWeight.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless" style={{}}>
                              Long Min Markup
                            </td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={longMinMarkup}
                                  onChange={handleLongMinMarkup}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageLongMinMarkup.toFixed(
                              0
                            )}%`}</td>
                          </tr>

                          <tr className="borderless">
                            <td className="borderless">Leverage</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={leverage}
                                  onChange={handleLeverage}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageLeverage.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless" style={{}}>
                              Long Markup Weight
                            </td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={longMarkupWeight}
                                  onChange={handleLongMarkupWeight}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageLongMarkupWeight.toFixed(
                              0
                            )}%`}</td>
                          </tr>

                          <tr className="borderless">
                            <td className="borderless">Time Offset</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={timeOffset}
                                  onChange={handleTimeOffset}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageTimeOffset.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless" style={{}}>
                              Short Min Markup
                            </td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={shortMinMarkup}
                                  onChange={handleShortMinMarkup}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageShortMinMarkup.toFixed(
                              0
                            )}%`}</td>
                          </tr>

                          <tr className="borderless">
                            <td className="borderless">Price Threshold</td>
                            <td className="borderless">
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={priceThreshold}
                                  onChange={handlePriceThreshold}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentagePriceThreshold.toFixed(
                              0
                            )}%`}</td>
                            <td className="borderless" style={{}}>
                              Min Markup Weight
                            </td>
                            <td className="borderless">
                              {" "}
                              <Box className="" sx={{ width: 100 }}>
                                {/* <MuiSlider color="primary" min={0} max={200} orientation="horizontal" /> */}
                                <CustomSlider
                                  value={minMarkupWeight}
                                  onChange={handleMinMarkupWeight}
                                  min={0}
                                  max={200}
                                  orientation="horizontal"
                                />
                              </Box>
                            </td>
                            <td className="borderless">{`${percentageMinMarkupWeight.toFixed(
                              0
                            )}%`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* coins window starts */}

              {configStep === 3 && (
                <div
                  className="container border-start"
                  style={{ borderColor: "blue" }}
                >
                  <div
                    className="mt-1 pt-4 ps-md-3"
                    style={{ minHeight: "322px" }}
                  >
                    <div className="mb-3">
                      <h4>Coins Active</h4>
                    </div>
                    <div className="mt-2 d-flex row row-cols-5 mx-1">
                      {listOfCoins.map((coin, index) => (
                        <SettingsCoin
                          key={index}
                          coin={coin}
                          toggleCoinSelection={toggleCoinSelection}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* coins modal ends here */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAllSliders}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
