import React from 'react'
import { Link } from 'react-router-dom'

function BlogItem({ id, title, description, imageSrc }) {
  const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/static/${imageSrc}`;
  // console.log("Image Src:", imageUrl); // Log the imageSrc prop value to the console

  return (
    <div>
      <div
        className="card text-white col-12 my-3"
        style={{ backgroundColor: "transparent", border: "none", minHeight: "510px" }}
      >
        <img
          src={imageUrl}
          className="card-img-top img-fluid"
          alt={title}
        />
        <div
          className="card-body"
          style={{ backgroundColor: "var(--color-gray-200)" }}
        >
          <h5 className="card-title">{title}</h5>
          <p className="card-text">
            {description.slice(0, 208)}...
          </p>
          <p className="card-text text-white">
            <Link to={`/blog/${id}`} className="text-white text-decoration-none">
              Read More
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default BlogItem
